import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  IChildTimesheet,
  IProject,
  ITask,
  ITimesheet,
} from "src/slices/timesheet/types";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllProjects,
  selectAllTasks,
  selectTimesheetList,
} from "src/slices/timesheet/selectors";
import {
  selectNewProject,
  selectNewTask,
  setEditTimesheetIndex,
  setHoursForDay,
} from "src/slices/timesheet";
import { AppDispatch } from "src/store";
import { addDays } from "date-fns";

interface RootProps {
  isTitleBold?: boolean;
}

const HeaderComponent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isTitleBold",
})<RootProps>(({ theme, isTitleBold }) => ({
  height: "52px",
  width: "100%",
  minWidth: "80px",
  borderRadius: "10px",
  border: "1px solid #595757",
  color: "#1e1e1e",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: isTitleBold ? 600 : 100,
  lineHeight: "normal",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 20px",
}));

const InputField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isTitleBold",
})<RootProps>(({ theme, isTitleBold }) => ({
  height: "52px",
  width: "100%",
  minWidth: "80px",
  // borderRadius: "10px",
  // border: "1px solid #595757",
  color: "#1e1e1e",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: isTitleBold ? 600 : 100,
  lineHeight: "normal",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  "& input":{
    border:"none",
    textAlign:"center"
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: "10px",
      border: "1px solid #595757",
    },
    '&:hover fieldset': {
      borderRadius: "10px",
      border: "1px solid primary",
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary',
    },
  },
  // padding: "0 20px",
}));

export const TimeSheet = ({ week, setEditTimesheet, editTimesheet }) => {
  const dispatch = useDispatch<AppDispatch>();
  const timesheetListing: ITimesheet[] = useSelector(selectTimesheetList);
  const allProjects: IProject[] = useSelector(selectAllProjects);
  const allTasks: ITask[] = useSelector(selectAllTasks);

  const getWeekDaysHoursList = (childTSheet: IChildTimesheet[]) => {
    const daysObj = {
      sun: {
        date: week.lastDay,
        hours: '',
      },
      mon: {
        date: week.firstDay,
        hours: 0,
      },
      tue: {
        date: addDays(week.firstDay, 1),
        hours: 0,
      },
      wed: {
        date: addDays(week.firstDay, 2),
        hours: 0,
      },
      thu: {
        date: addDays(week.firstDay, 3),
        hours: 0,
      },
      fri: {
        date: addDays(week.firstDay, 4),
        hours: 0,
      },
      sat: {
        date: addDays(week.firstDay, 5),
        hours: 0,
      },
    };
    let sum = 0;
    childTSheet.forEach((childSheet) => {
      sum += parseFloat(childSheet.hours.toString())||0;
      daysObj[Object.keys(daysObj)[new Date(childSheet.date).getDay()]] = {
        date: new Date(new Date(childSheet.date).setHours(0)),
        hours: childSheet.hours,
      };
    });
    return [
      daysObj.mon,
      daysObj.tue,
      daysObj.wed,
      daysObj.thu,
      daysObj.fri,
      daysObj.sat,
      daysObj.sun,
      {
        date: null,
        hours: sum,
      },
    ];
  };

  return (
    <Stack direction="column" spacing={2} sx={{ overflow: "scroll" }}>
      <Stack direction="row" spacing={2}>
        <HeaderComponent isTitleBold>Project</HeaderComponent>
        <HeaderComponent isTitleBold>Task</HeaderComponent>
        <HeaderComponent isTitleBold>Mon</HeaderComponent>
        <HeaderComponent isTitleBold>Tue</HeaderComponent>
        <HeaderComponent isTitleBold>Wed</HeaderComponent>
        <HeaderComponent isTitleBold>Thu</HeaderComponent>
        <HeaderComponent isTitleBold>Fri</HeaderComponent>
        <HeaderComponent isTitleBold>Sat</HeaderComponent>
        <HeaderComponent isTitleBold>Sun</HeaderComponent>
        <HeaderComponent isTitleBold>Total</HeaderComponent>
        <HeaderComponent sx={{ visibility: "hidden" }}>Delete</HeaderComponent>
        {/* <HeaderComponent sx={{ visibility: "hidden" }}>Submit</HeaderComponent> */}
      </Stack>
      {timesheetListing
        .filter((timesheet) => timesheet.childTimeSheets)
        .map((project) => (
          <>
            {project.childTimeSheets.map((childTimeSheet, index) => (
              <>
                <Stack direction="row" spacing={2}>
                  {index === editTimesheet ||
                  (timesheetListing &&
                    timesheetListing[0].childTimeSheets &&
                    timesheetListing[0].childTimeSheets[0][0]
                      .childTimeSheetId === -1 &&
                    index === 0) ? (
                    <FormControl
                      sx={{
                        height: "52px",
                        width: "100%",
                        minWidth: "80px",
                        borderRadius: "10px",
                        border: "1px solid #595757",
                        color: "#1e1e1e",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 20px",
                      }}
                    >
                      <Tooltip title={allProjects.find(project => project.projectCode===childTimeSheet?.[0]?.projectCode)?.name||''}>
                      <Select
                        variant="standard"
                        disableUnderline
                        value={childTimeSheet?.[0].projectCode}
                        onChange={(event: SelectChangeEvent) => {
                          const selectedProject = allProjects.filter(
                            (project) =>
                              project.projectCode === event.target.value
                          )[0];
                          dispatch(selectNewProject(selectedProject));
                        }}
                        sx={{ fontSize: "11px", textOverflow:'ellipsis', width:'100%' }}
                      >
                        {allProjects.map((project) => (
                          <MenuItem key={project.projectId} value={project.projectCode}>
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>
                      </Tooltip>
                    </FormControl>
                  ) : (
                    <HeaderComponent sx={{ fontSize: "11px", cursor:'pointer' }}>
                      <Tooltip title={childTimeSheet?.[0].projectName}>
                        <Typography noWrap>{childTimeSheet?.[0].projectName}</Typography>
                      </Tooltip>
                    </HeaderComponent>
                  )}
                  {index === editTimesheet ||
                  (timesheetListing &&
                    timesheetListing[0].childTimeSheets &&
                    timesheetListing[0].childTimeSheets[0][0]
                      .childTimeSheetId === -1 &&
                    index === 0) ? (
                    <FormControl
                      sx={{
                        height: "52px",
                        width: "100%",
                        minWidth: "80px",
                        borderRadius: "10px",
                        border: "1px solid #595757",
                        color: "#1e1e1e",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 20px",
                        textOverflow:'ellipsis'
                      }}
                    >
                      <Tooltip title={allTasks.find(task => task.id===childTimeSheet?.[0]?.taskId)?.name||''}>
                        <Select
                          variant="standard"
                          disableUnderline
                          value={childTimeSheet?.[0].taskId?.toString() ?? null}
                          onChange={(event: SelectChangeEvent) => {
                            const selectedTask = allTasks.filter(
                              (task) => task.id.toString() === event.target.value
                            )[0];
                            dispatch(selectNewTask(selectedTask));
                          }}
                          sx={{ fontSize: "11px", textOverflow:'ellipsis', width:'100%' }}
                        >
                          {allTasks.map((task) => (
                            <MenuItem key={task.id} value={task.id?.toString()}>
                              {task.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Tooltip>
                    </FormControl>
                  ) : (
                    <HeaderComponent sx={{ fontSize: "11px", cursor:'pointer' }}>
                      <Tooltip title={childTimeSheet?.[0].taskName}>
                        <Typography noWrap>{childTimeSheet?.[0].taskName}</Typography>
                      </Tooltip>
                    </HeaderComponent>
                  )}

                  {getWeekDaysHoursList(childTimeSheet).map(
                    (singleChildTimesheet, singleChildsheetIndex) => (
                      <>
                        {(index === editTimesheet ||
                          (timesheetListing &&
                            timesheetListing[0].childTimeSheets &&
                            timesheetListing[0].childTimeSheets[0][0]
                              .childTimeSheetId === -1 &&
                            index === 0)) &&
                        singleChildsheetIndex !== 7 ? (
                          <InputField
                            type="number"
                            value={singleChildTimesheet.hours}
                            onChange={
                              (e) => {
                                dispatch(
                                  setHoursForDay({
                                    hours: e.target.value,
                                    date: singleChildTimesheet.date,
                                    childsheetIndex: index,
                                    singleChildsheetIndex:
                                      singleChildsheetIndex,
                                  })
                                );
                              }
                            }
                          />
                        ) : (
                          <HeaderComponent>
                            {singleChildTimesheet.hours}
                          </HeaderComponent>
                        )}
                      </>
                    )
                  )}
                  {timesheetListing?.[0]?.isLocked ||
                  (timesheetListing &&
                    timesheetListing[0].childTimeSheets &&
                    (timesheetListing?.[0].childTimeSheets[index]?.[0]
                      .approvalStatus === "Pending Approval" ||
                      timesheetListing?.[0].childTimeSheets[index]?.[0]
                        .approvalStatus === "Accepted" ||
                      timesheetListing?.[0].childTimeSheets[index]?.[0].isLocked ||
                      timesheetListing?.[0].childTimeSheets?.[0]?.[0]
                        .childTimeSheetId === -1)) ? (
                    <HeaderComponent sx={{ borderColor: "gray", opacity: 0.3 }}>
                      <EditIcon />
                    </HeaderComponent>
                  ) : (
                    <HeaderComponent
                      sx={{
                        "&:hover": {
                          borderColor: "red",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        dispatch(setEditTimesheetIndex(index));
                        setEditTimesheet(index);
                      }}
                    >
                      <EditIcon />
                    </HeaderComponent>
                  )}
                  {/* {timesheetListing?.[0].isLocked ||
                  timesheetListing?.[0].childTimeSheets[index]?.[0]
                    .approvalStatus === "Pending Approval" ||
                  timesheetListing?.[0].childTimeSheets[index]?.[0]
                    .approvalStatus === "Accepted" ||
                  timesheetListing?.[0].childTimeSheets[index]?.[0].isLocked ||
                  timesheetListing?.[0].childTimeSheets?.[0]?.[0].childTimeSheetId ===
                    -1 ? (
                    <HeaderComponent sx={{ borderColor: "gray", opacity: 0 }}>
                      Submit
                    </HeaderComponent>
                  ) : (
                    <HeaderComponent
                      sx={{
                        fontSize: "11px",
                        "&:hover": {
                          borderColor: "red",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        dispatch(
                          sumbitTimesheet({
                            timeSheetId: timesheetListing?.[0].timeSheetId,
                            employeeProjectId:
                              childTimeSheet?.[0].employeeProjectId,
                          })
                        );
                      }}
                    >
                      Submit
                    </HeaderComponent>
                  )} */}
                </Stack>
              </>
            ))}
          </>
        ))}
    </Stack>
  );
};
