export const columns = [
  {
    accessorKey: "employeename",
    header: "Employee Name",
    size: 40,
  },
  {
    accessorKey: "projectname",
    header: "Project Name",
    size: 40,
  },
  {
    accessorKey: "taskName",
    header: "Task",
    size: 40,
  },
  {
    accessorKey: "approvalstatusname",
    header: "Approval Status",
    size: 40,
  },
  {
    accessorKey: "hours",
    header: "Hours",
    size: 40,
  },
  {
    accessorKey: "submissionDate",
    header: "Submission Date",
    size: 40,
  },
 
];