import Add from "@mui/icons-material/Add";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { endOfWeek, format, startOfWeek } from "date-fns";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { HonestWeekPicker } from "src/components/WeekPicker/WeekPicker";
import {
  ContainedButton,
  ContainedSecondaryButton,
} from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { TimeSheet } from "src/components/pages/home/timesheet";
import { selectUser } from "src/slices/auth/selectors";
import { toastMessage } from "src/slices/commonSlice";
import { addNewTimesheet } from "src/slices/timesheet";
import { selectTimesheetList } from "src/slices/timesheet/selectors";
import {
  editTimesheet,
  fetchAllProjects,
  fetchAllTasks,
  fetchTimesheetList,
  saveTimesheet,
  sumbitTimesheet,
  unsumbitTimesheet,
} from "src/slices/timesheet/thunks";
import { ITimesheet } from "src/slices/timesheet/types";
import { AppDispatch } from "src/store";

export const WeeklyWorksheet = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { state } = useLocation();

  const [week, setWeek] = useState(() => {
    if (state?.week) return state.week;

    return {
      firstDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
      lastDay: endOfWeek(new Date(), { weekStartsOn: 1 }),
    };
  });

  const [editTSIndex, setEditTSIndex] = useState(-1);

  const timesheetListing: ITimesheet[] = useSelector(selectTimesheetList);
  const user = useSelector(selectUser);
  const editTimesheetIndex = useSelector(
    (state: any) => state.timesheet.editTimesheetIndex
  );

  // const convertDate = (date) => {
  //   let dt = new Date(date);
  //   return `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}`;
  // };

  const onChange = (week) => {
    setWeek(week);
  };

  useEffect(() => {
    dispatch(fetchAllProjects());
    dispatch(fetchAllTasks());
  }, [dispatch]);

  useEffect(() => {
    if (editTimesheetIndex === -1) {
      dispatch(
        fetchTimesheetList({
          startDate: format(week.firstDay, "yyyy-MM-dd"),
          endDate: format(week.lastDay, "yyyy-MM-dd"),
        })
      );
    }
  }, [dispatch, week, editTimesheetIndex]);

  const onsubmit = () => {
    timesheetListing[0].overallStatus !== 'Pending Approval' ? dispatch(
      sumbitTimesheet({
        timeSheetId: timesheetListing?.[0].timeSheetId,
        employeeProjectId: null,
      })
    ).then(() => {
      dispatch(
        fetchTimesheetList({
          startDate: format(week.firstDay, "yyyy-MM-dd"),
          endDate: format(week.lastDay, "yyyy-MM-dd"),
        })
      );
      setEditTSIndex(-1);
    }) : dispatch(
      unsumbitTimesheet({
        timeSheetId: timesheetListing?.[0].timeSheetId,
        employeeProjectId: null,
      })
    ).then(() => {
      dispatch(
        fetchTimesheetList({
          startDate: format(week.firstDay, "yyyy-MM-dd"),
          endDate: format(week.lastDay, "yyyy-MM-dd"),
        })
      );
      setEditTSIndex(-1);
    })
  }

  return (
    <Grid container spacing={1} minHeight="100vh" alignContent="start">
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            Select Week:{" "}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <HonestWeekPicker
              week={week}
              setWeek={setWeek}
              onChange={onChange}
            />
          </Box>
          <IconButton
            onClick={() => {
              if(timesheetListing.length === 0){
                dispatch(
                  toastMessage({
                    error: true,
                    message: "No timesheet available for this week!",
                  })
                );
              }
              if (!timesheetListing?.[0]?.isLocked) {
                dispatch(addNewTimesheet(week));
                setEditTSIndex(0);
              } else {
                dispatch(
                  toastMessage({
                    error: true,
                    message: "This timesheet is locked!",
                  })
                );
              }
            }}
          >
            <Add color="primary" sx={{ fontSize: "30px" }} />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TimeSheet
          week={week}
          setEditTimesheet={setEditTSIndex}
          editTimesheet={editTSIndex}
        />
      </Grid>
      {timesheetListing.filter((timesheet) => timesheet.childTimeSheets)
        .length !== 0 && (
        <Grid item xs={12}>
          <Stack direction="row-reverse" spacing={2} mt={5}>
            {user?.employeeInfo?.roleId !== 3 && (
              <ContainedButton
                sx={{
                  width: "241px",
                  height: "50px",
                  borderRadius: "10px",
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
                disabled={
                  editTSIndex !== -1 ||
                  ((timesheetListing &&
                    (timesheetListing?.[0].childTimeSheets &&
                    timesheetListing?.[0].childTimeSheets?.[0]?.[0]
                      .childTimeSheetId === -1)) || timesheetListing[0].isLocked)
                }
                onClick={() => {
                  onsubmit()
                }}
              >
                {timesheetListing[0].overallStatus !== 'Pending Approval' ? 'Submit For Approval' : 'Unsubmit'}
              </ContainedButton>
            )}
            <ContainedSecondaryButton
              color="secondary"
              sx={{
                height: "50px",
                borderRadius: "10px",
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
              disabled={editTSIndex === -1 || timesheetListing[0].isLocked}
              onClick={() => {
                
                let sum = 0;

                if(timesheetListing && timesheetListing?.[0].childTimeSheets){
                  for(let i = 0; i < timesheetListing?.[0].childTimeSheets.length; i++){
                    for(let j = 0; j < timesheetListing?.[0].childTimeSheets[i].length; j++){
                      sum += parseInt(timesheetListing?.[0].childTimeSheets[i][j].hours.toString());
                    }
                  }
                }
                if(sum > 40){
                  dispatch(toastMessage({
                    error: true,
                    message: "Total hours for a timesheet cannot be above 40"
                  }));
                  return;
                }

                if (
                  timesheetListing &&
                  timesheetListing?.[0].childTimeSheets &&
                  timesheetListing?.[0].childTimeSheets[0][0].childTimeSheetId ===
                    -1
                ) {
                  dispatch(saveTimesheet()).then(() => {
                    dispatch(
                      fetchTimesheetList({
                        startDate: format(week.firstDay, "yyyy-MM-dd"),
                        endDate: format(week.lastDay, "yyyy-MM-dd"),
                      })
                    );
                    setEditTSIndex(-1);
                  });
                } else {
                  dispatch(editTimesheet()).then(() => {
                    dispatch(
                      fetchTimesheetList({
                        startDate: format(week.firstDay, "yyyy-MM-dd"),
                        endDate: format(week.lastDay, "yyyy-MM-dd"),
                      })
                    );
                  });
                  setEditTSIndex(-1);
                }
              }}
            >
              Save
            </ContainedSecondaryButton>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Comment
        </Typography>
        <Formik initialValues={{ comment: "" }} onSubmit={() => {}}>
          <FormikControl
            control="Textarea"
            name="comment"
            label="Comment"
            multiline
            rows={5}
            //   error={touched.email && Boolean(errors.email)}
            //   helperText={touched.email && errors.email}
            //   {...getFieldProps("email")}
          />
        </Formik>
      </Grid>
      {/* <div>{convertDate(week.firstDay)}</div> */}
    </Grid>
  );
};
