import { combineReducers } from "@reduxjs/toolkit";

import { reducer as loginInformation } from "../slices/auth";
import { reducer as commonReducer } from "../slices/commonSlice";
import { reducer as uiSettings } from "../slices/uiSettingsSlice";
import { reducer as timesheetReducer } from "../slices/timesheet";
import { reducer as customerReducer } from "../slices/customer";
import { reducer as employeeReducer } from "../slices/employee";
import { reducer as projectReducer } from "../slices/project";

const appReducer = combineReducers({
  commonReducer,
  uiSettings,
  loginInformation,
  timesheet: timesheetReducer,
  customer: customerReducer,
  employee: employeeReducer,
  project: projectReducer
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "loginInformations/logOutUser") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
